import React from "react";
import { Helmet } from "react-helmet";
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";

import "../static/assets/css/plugins.css";
import "../static/assets/css/style.css";

const menuItems = [
    { link: "/", name: "Home", backgroundImage: "/assets/images/home/anonymous-person.png", backgroundPosition: "right center", },
    { link: "/about", name: "About", backgroundImage: "/assets/images/about/about-header.jpg", },
    { link: "/work-for-us", name: "Work for us", backgroundImage: "/assets/images/work-for-us/empty-desk.jpg", },
    { link: "/contact", name: "Contact", backgroundImage: "/assets/images/contact/contact-hero.jpg", }
];

const App = (props: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) => {
    return (
        <>
            <Helmet bodyAttributes={{
                class: "loader",
            }}>
                <title>INEPRA</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7COswald:300,400,500,600,700" rel="stylesheet" type="text/css" />
            </Helmet>
            <div className="loading">
                <img className="logo-loading" src="/assets/images/logo/logo-loader.png" alt="logo" />
            </div>
          
            <div className="pointer" id="pointer">
                <i className="fas fa-long-arrow-alt-right"></i>
                <i className="fas fa-search"></i>
                <i className="fas fa-link"></i>
            </div>

            <a className="to-top-btn pointer-small" href="#up">
                <span className="to-top-arrow"></span>		    
            </a>
            
            <header className="fixed-header">
                <div className="header-flex-box">
                    <a href="/" className="logo pointer-large animsition-link">
                        <div className="logo-img-box">
                            <img className="logo-white" src="/assets/images/logo/logo-white.png" alt="logo" />
                            <img className="logo-black" src="/assets/images/logo/logo-black.png" alt="logo" />
                        </div>
                    </a>

                    <div className="menu-open pointer-large">
                        <span className="hamburger"></span>
                    </div>
                </div>
            </header>
        
            <nav className="nav-container dark-bg-1">
                <div className="nav-logo">
                    <img src="/assets/images/logo/logo-white.png" alt="logo" />
                </div>
          
                <div className="menu-close pointer-large"></div>
        
                <div className="dropdown-close-box">
                    <div className="dropdown-close pointer-large">
                        <span></span>
                    </div>
                </div>
        
                <ul className="nav-menu dark-bg-1">
                    {menuItems.map((menuItem, i) => {
                        const isActive = "";// window.location.pathname === menuItem.link ? "active" : "";

                        return (
                            <li key={i} className={`nav-box nav-bg-change ${isActive}`}>
                                <a className="pointer-large nav-link" href={menuItem.link}>
                                    <span className={`nav-btn ${isActive}`} data-text={menuItem.name}>{menuItem.name}</span>
                                </a>
                    
                                <div className="nav-bg" style={{ backgroundImage: `url(${menuItem.backgroundImage})`, backgroundPosition: menuItem.backgroundPosition, }}></div>
                            </li>

                        );
                    })}
                </ul>
            </nav>

            <main className="animsition-overlay" data-animsition-overlay="true">
                {props.element}
            </main>
        
            <footer className="footer dark-bg-1">
                <div className="flex-container container top-bottom-padding-90">
                    <div className="three-columns bottom-padding-60 footer-column">
                        <div>
                            <img className="footer-logo" src="/assets/images/logo/logo-white.png" alt="logo" />
                        </div>
                    </div>
                    <div className="six-columns bottom-padding-60 footer-column">
                        <ul className="footer-menu text-color-4" style={{ textAlign: "center", }}>
                            {menuItems.map((menuItem, i) => {
                                const isActive = ""; //window.location.pathname === menuItem.link ? "active" : "";

                                return (
                                    <li key={i}><a className={`pointer-large animsition-link small-title-oswald hover-color ${isActive}`} href={menuItem.link}>{menuItem.name}</a></li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="three-columns bottom-padding-60 footer-column footer-column--right">
                        <ul className="footer-information text-color-4">
                            {/* <li><i className="far fa-envelope"></i><a href="mailto:info@inepra.nl" className="xsmall-title-oswald">info@inepra.nl</a></li> */}
                            <li><i className="fas fa-mobile-alt"></i><a href="tel:+31 3 20 85 99 35" className="xsmall-title-oswald">+31 3 20 85 99 35</a></li>
                            <li><i className="fas fa-map-marker-alt"></i><a href="https://goo.gl/maps/CqkAzRxVZWEQYRyz7" target="_blank" className="xsmall-title-oswald text-height-17" rel="noreferrer">Zilverparkkade 64<br /><span>8232 WK Lelystad, NL</span></a></li>
                        </ul>
                    </div>
                    <div className="twelve-columns">
                        <p className="p-letter-style text-color-4 footer-copyright">&copy; Copyright {new Date().getFullYear()} Inepra | All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default App;
