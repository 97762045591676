import { navigate } from "gatsby";  
import $ from "jquery";

window.$ = $;
window.jQuery = $;

import "./ScrollAnimations";
import "./animsition";
import "./midnight.jquery";
import "./jQuery.headroom";
import "./footer-reveal";

import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export const handleInitialPageLoad =  () => {
    $(function() {
        loadInitialScripts();
    });
};

/**
 * Load scripts that only need to be initiated on first page load
 */
const loadInitialScripts = () => {
    "use strict";

    /* 1. Custom mouse cursor */
    document.getElementsByTagName("body")[0].addEventListener("mousemove", function(n) {
        if (e) {
            e.style.left = n.clientX + "px", 
            e.style.top = n.clientY + "px";
        }
    });
    var e = document.getElementById("pointer");
        
    $(document).mousemove(function(e){
        $(".red-bg")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("black");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("black");	  
            });
        
        $(".pointer-large, .swiper-button-next, .swiper-button-prev, .mfp-arrow-left, .mfp-arrow-right, .home-slider .swiper-pagination-bullet")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("large");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("large");	  
            });
        
        $(".pointer-small, .testimonials-slider .swiper-pagination-bullet, .portfolio-slider .swiper-pagination-bullet")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("small");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("small");	  
            });
        
        $(".mfp-img, .pointer-right")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("right");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("right");	  
            });
        
        $(".pointer-zoom")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("zoom");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("zoom");	  
            });
        
        $(".pointer-open")
            .on("mouseenter", function() {	 
                $(".pointer").addClass("open");	  
            })
            .on("mouseleave", function() {	  
                $(".pointer").removeClass("open");	  
            });
        
    });
        
    /* 8. Change menu background */
    $(document).on("mouseover", ".nav-bg-change", function(){		
        $(this).addClass("active").siblings().removeClass("active");
    });

    /* 10. Headroom */
    setTimeout(() => {
        $(".fixed-header").headroom();
    }, 100);
    
    /* 13. Touch, no touch */
    var supports = (function() {
        var d = document.documentElement,
            c = "ontouchstart" in window || navigator.msMaxTouchPoints;
        if (c) {
            d.className += " touch";
            return {
                touch: true,
            };
        } else {
            d.className += " no-touch";
            return {
                touch: false,
            };
        }
    })();

    // /* 14. fixed footer - doees not work when fast refreshing */
    // $("footer").footerReveal({ 
    //     shadow: false,
    //     zIndex : 1,
    // });

    /* 15. Progress bar */
    $(".progress-zero").each(function(){
        $(this).find(".progress-full").animate({
            width: $(this).attr("data-progress"),
        });
    });
};

/**
 * Load scripts that need to be initiated on every page load, including navigation and fast refresh
 */
export const handleSubsequentPageLoad = async (forceInit) => {
    if (forceInit) {
        loadInitialScripts();
        $(".animsition-overlay-slide").remove();
    }

    /* 2. Animsition preloader */
    $(".animsition-overlay").animsition({
        inClass: "overlay-slide-in-right",
        outClass: "overlay-slide-out-right",
        inDuration: 1,
        outDuration: 1500,
        linkElement: "a:not([target='_blank']):not([href^='#']):not([href^='tel:'])",
        loading: false,
        loadingParentElement: "body", //animsition wrapper element
        loadingClass: "animsition-loading",
        loadingInner: "", // e.g '<img src="loading.svg" />'
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: false,
        browser: [ "animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay : true,
        overlayClass : "animsition-overlay-slide",
        overlayParentElement : "body",
        transition: function(url){
            navigate(url);
        },
    });
    
    $("body").on("animsition.outStart", function(){
        $("body").removeClass("active");
        $("body").addClass("out");
    });

    $("body").on("animsition.inStart", function(){
        $(".menu-open, .nav-container").removeClass("active");
    });

    $("body").on("animsition.inEnd", function(){
        $("body").addClass("active");
        $("body").addClass("in");
        setTimeout(function () {      
            $("body").addClass("anim"); 
        }, 1000);

        initHeaderElements();
        initContactForm();
    });

    $("body").on("animsition.outEnd", function(e){
        $("body").removeClass("out");
        $(".animsition-overlay-slide").remove();
    });

    $(".animsition-overlay").animsition("in");

    /* 9. ScrollAnimations */
    var $containers = $("[data-animation]:not([data-animation-child]), [data-animation-container]");
    $containers.scrollAnimations();

    // Enable smooth scrolling
    $("a[href^='#']").on("click", function(e) {
        e.preventDefault();
        const href = $(this).attr("href");
        const target = $(href);

        $("html, body").animate({ scrollTop: target.offset().top, }, 800);
    });
};

const initHeaderElements = () => {
    /* 5. Midnight */
    $(".fixed-header").midnight();
            
    /* 6. Navigation open/close */
    $( ".menu-open" ).on( "click", function() {
        $(".menu-open, .nav-container").addClass("active");
    });

    $( ".menu-close" ).on( "click", function() {
        $(".menu-open, .nav-container").removeClass("active");
    });

    /* 7. Drop-down menu */
    $(".dropdown-open").on("click",function(){
        $(this).find(".dropdown").addClass("active");
        $(".nav-link").addClass("done");
        $(".dropdown-close").addClass("active");
    });

    $(".dropdown-close").on("click",function(){    
        $(".dropdown").removeClass("active");
        $(".nav-link").removeClass("done");
        $(".dropdown-close").removeClass("active");
    });
};

const initContactForm = () => {

    /* 16. Contact form */
    $("#send_form").on("submit", function(){
        var first_name = $("#first_name").val().trim();
        var last_name = $("#last_name").val().trim();
        var email = $("#email").val().trim();
        var message = $("#message").val().trim();

        $.ajax({
            url: "https://usebasin.com/f/93c73de144bd",
            type: "POST",
            cache: false,
            data: {"first_name": first_name, "last_name": last_name, "email": email, "message": message,},
            dataType: "json",
            beforeSend: function() {
                $("#send").addClass("js-active");
            },
            success: function(data) {
                console.log("data", data);
                if(!data || !data.success){
                    $("#m_err").addClass("js-active");
                    $(".form-box").addClass("js-active");
                    setTimeout(function () {
                        $("#send").removeClass("js-active");
                    }, 2000);
                }

                else {
                    $("#m_sent").addClass("js-active");
                    $(".form-box").addClass("js-active");
                    setTimeout(function () {
                        $("#send").removeClass("js-active");
                        $("#send_form").trigger("reset");
                        $(".email-label").removeClass("js-active");
                    }, 2000);
                }
                $(".js-popup-close").click(function() {
                    $(this).parents(".js-popup-fade").removeClass("js-active");
                    $(".form-box").removeClass("js-active");
                    return false;
                });

                $(document).keydown(function(e) {
                    if (e.keyCode === 27) {
                        e.stopPropagation();
                        $(".js-popup-fade").removeClass("js-active");
                        $(".form-box").removeClass("js-active");
                    }
                });

                $(".js-popup-fade").click(function(e) {
                    if ($(e.target).closest(".js-popup").length == 0) {
                        $(".js-popup-fade").removeClass("js-active");
                        $(".form-box").removeClass("js-active");
                    }
                });
            },
        });
        return false;
    });

    $("#email").on("keyup",function(){
        var $this = $(this),
            val = $this.val();

        if(val.length >= 1){
            $(".email-label").addClass("js-active");
        }
        else {
            $(".email-label").removeClass("js-active");
        }
    });
};
