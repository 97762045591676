import React, { useEffect } from "react";
import { handleInitialPageLoad, handleSubsequentPageLoad } from "./static/assets/js/main";
import App from "./src/App";

const Wrapper = (props) => {
    useEffect(() => {
        return () => {
            // When the app fast reloads in development mode, record it so jQuery scripts will be properly loaded
            window.localStorage.setItem("didDismount", true);
        };
    }, []);

    useEffect(() => {
        const didDismount = window.localStorage.getItem("didDismount") === "true";
        handleSubsequentPageLoad(!!didDismount);
        
        window.localStorage.setItem("didDismount", false);
    }, [props.props.location.key]);

    return (
        <App {...props} />
    );
};

export const wrapPageElement = (props) => <Wrapper {...props} />;

export const onInitialClientRender = () => {
    handleInitialPageLoad();
    window.localStorage.setItem("didDismount", false);
};
